import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import PageTransition from "gatsby-plugin-page-transitions"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { UnstyledList } from "../components/commons"
import { linkResolver } from "../linkResolver"

import ArrowDown from "../images/arrow-down.png"

const Items = styled(UnstyledList)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 1.9rem;
`

const StyledItem = styled.li`
  display: flex;
  height: 100vh;
  align-items: center;

  a {
    text-decoration: none;
    color: #000;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 2.8rem;

  > div {
    display: flex;
  }

  &:hover {
    h2 {
      color: rgba(0, 0, 0, 0.65);
    }

    .item-link {
      &:after {
        opacity: 1;
      }
    }

    .item-link-number {
      opacity: 0;
    }
  }
`

const Subtitle = styled.span`
  font-family: "visionbold";
  text-transform: uppercase;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  line-height: 0.9;
  font-size: 22px;
  margin-right: 1.3rem;
  letter-spacing: 4px;
  text-align: right;
  display: inline-flex;
`

const Pagination = styled.span`
  font-family: "visionbold";
  text-transform: uppercase;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  line-height: 0.8;
  font-size: 21px;
  letter-spacing: 4.5px;
  text-align: right;
  display: inline-flex;
  position: relative;
  right: -45px;
  top: 66px;
`

const Title = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
`

const H2 = styled.h2`
  position: absolute;
  z-index: 100;
  font-weight: 900;
  font-size: 164px;
  letter-spacing: 0px;
  left: -457px;
  font-family: "Playfair Display", serif;
  bottom: 88px;
  color: #000;
  -webkit-text-stroke: none;
  transition: all 0.2s linear;
`

const ItemLink = styled.div`
  display: flex;
  font-family: "Playfair Display", serif;
  font-size: 21px;
  font-weight: 900;
  transition: all 0.2s linear;
  position: relative;
  align-items: flex-end;
  justify-content: flex-end;
  right: -83px;

  a {
    color: #000;
    text-decoration: none;
  }

  span {
    opacity: 1;
    transition: all 0.2s linear;
  }

  &:before {
    content: "";
    position: relative;
    bottom: 6px;
    height: 2px;
    background-color: #000;
    width: 75px;
    display: block;
    margin-right: 16px;
  }

  &:after {
    content: "explorer";
    color: #000;
    margin-left: -24px;
    opacity: 0;
    transition: all 0.2s linear;
  }
`

const Next = styled.img`
  position: absolute;
  margin: 0 auto;
  bottom: 1.5rem;
  left: 0;
  right: 0;
`

function Item(item, index, array) {
  const number = index + 1
  const title = item.node.title[0].text
  const category = item.node.category[0].text
  const year = item.node.year[0].text
  const src = item.node.image && item.node.image.url
  const uid = item.node._meta.uid

  return (
    <StyledItem key={title}>
      <Link to={linkResolver({ type: "project", uid })}>
        <Inner>
          <div>
            <Subtitle>
              {category} <br />
              {year}
            </Subtitle>
            {src && <img src={src} height={390} width={626} alt={title} />}
            <Title>
              <H2>{title}</H2>
            </Title>
            <Pagination>{`${number}—${array.length}`}</Pagination>
          </div>
          <ItemLink className="item-link">
            <span className="item-link-number">{`0${number}`.slice(-2)}</span>
          </ItemLink>
        </Inner>
      </Link>
      <Next width={16} src={ArrowDown} alt="Next" />
    </StyledItem>
  )
}

function IndexPage({ data }) {
  const items = data.prismic.allProjects.edges

  return (
    <PageTransition>
      <Layout>
        <SEO title="Home" />
        <Items>{items.map(Item)}</Items>
      </Layout>
    </PageTransition>
  )
}

export default IndexPage

export const IndexQuery = graphql`
  query Items {
    prismic {
      allProjects(sortBy: meta_firstPublicationDate_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            title
            category
            year
            image
          }
        }
      }
    }
  }
`
